import React from 'react'

import Layout from '../../../components/layout'
import { ApplicationSummary } from '../../../components/job'

const ApplicationSummaryPage = ({ params: { id } }) => {
  return (
    <Layout tocEnabled={true}>
      <ApplicationSummary id={id} />
    </Layout>
  )
}

export default ApplicationSummaryPage
